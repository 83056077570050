import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import * as Sentry from "@sentry/angular";
import { rewriteFramesIntegration } from '@sentry/integrations';
import { TranslateService } from "@ngx-translate/core";
import { environment } from "./environments/environment";
import { VERSION } from "./environments/version";



if (environment.production) {
  Sentry.init({
    release: "vectore-web-app-ng-16@" + VERSION,
    dsn: "https://4c4e428e034b6fef3edf63b0cbb25945@sentry.gsdev.click/18",
    maxBreadcrumbs: 10,
    environment: window.location.host.includes('vectore.cloud')? "production" : "staging",
    tracePropagationTargets: ["https://api.vectore.eu", "https://api.vectore.cloud"],

    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [window.location.origin, "https://api.vectore.eu", "https://api.vectore.cloud"],
        networkRequestHeaders: ["Cache-Control"],
        networkResponseHeaders: ["Referrer-Policy"],
        mutationBreadcrumbLimit: 1000,
        mutationLimit: 1500,
      }),
      Sentry.rewriteFramesIntegration(),
    ],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
  enableProdMode();
  console.log("production mode");
  console.log(VERSION);
}

const browserLang = navigator.language.split('-')[0];

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(moduleRef => {
    const translate = moduleRef.injector.get(TranslateService);
    translate.setDefaultLang(browserLang);
    translate.use(browserLang);
  })
  .catch(err => Sentry.captureEvent(err));