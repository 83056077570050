import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingModalService } from './booking-modal.service';
import { BookingModalComponent } from './booking-modal.component';
import { BookingModalStep1Component } from './booking-modal-step1/booking-modal-step1.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BookingModalFooterComponent } from './booking-modal-footer/booking-modal-footer.component';
import { BookingModalStep2Component } from './booking-modal-step2/booking-modal-step2.component';
import { BookingModalStep3Component } from './booking-modal-step3/booking-modal-step3.component';
import { LeafletModule } from '@bluehalo/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@bluehalo/ngx-leaflet-markercluster';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BookingModalVehiclesToShowComponent } from './booking-modal-step3/booking-modal-vehicles-to-show/booking-modal-vehicles-to-show.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BookingModalStepperComponent } from './booking-modal-stepper/booking-modal-stepper.component';
import { DropdownModule } from '../../dropdown/dropdown.module';
import { StepperComponent } from 'src/app/shared/components/stepper/stepper.component';
import { InputDatePickerComponent } from '../../inputs/input-date-picker/input-date-picker.component';
import { BadgeBookingComponent } from 'src/app/shared/components/badge/badge-booking/badge-booking.component';
import { ButtonChooseComponent } from '../../buttons/button-choose/button-choose.component';
import { PipeModule } from 'src/app/shared/pipes/pipe.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InputDateFormlyComponent } from '../../inputs/input-date-formly/input-date-formly.component';
import { FormlyModule } from '@ngx-formly/core';

@NgModule({
  declarations: [
    BookingModalComponent,
    BookingModalStep1Component,
    BookingModalStep2Component,
    BookingModalStep3Component,
    BookingModalVehiclesToShowComponent,
    BookingModalFooterComponent,
    BookingModalStepperComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    BrowserAnimationsModule,
    FormlyModule.forRoot({
      types: [
        {
          name: 'ngx-bootstra-datepicker',
          component: InputDateFormlyComponent,
        }
      ]
    }),

    TypeaheadModule.forRoot(),

    LeafletModule,
    LeafletMarkerClusterModule,

    AutoCompleteModule,
    DropdownModule,
    
    StepperComponent,
    InputDatePickerComponent,
    BadgeBookingComponent,
    ButtonChooseComponent,

    PipeModule,
  ],
  providers: [
    BookingModalService
  ]
})
export class BookingModalModule { }
