import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarButtonComponent } from './components/sidebar/sidebar-button/sidebar-button.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { NotificationComponent } from './components/notification/notification.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NoItemsComponent } from '../shared/components/no-items/no-items.component';
import { PipeModule } from '../shared/pipes/pipe.module';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [
    NavbarComponent,
    SidebarComponent,
    SidebarButtonComponent,
    BreadcrumbComponent,
    NotificationComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild(),
    InfiniteScrollModule,

    TooltipModule,
    
    NoItemsComponent,
    PipeModule
  ],
  exports: [
    NavbarComponent,
    SidebarComponent,
    BreadcrumbComponent
  ]
})
export class CoreModule { }
