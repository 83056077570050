import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { AccessUtilsService } from "src/app/shared/utilities/access-utils.servic";
import { ActionsModalComponent } from "src/app/shared/components/modals/actions-modal/actions-modal.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { VERSION } from "src/environments/version";
import { delay, of, retry, Subject, Subscription, takeUntil } from "rxjs";
import { MeService } from "src/app/shared/api-services/me.service"; import * as Sentry from "@sentry/angular";
import { Params } from '@angular/router';

@Component({
    selector: 'sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    standalone: false
})
export class SidebarComponent implements OnInit, OnDestroy {

  tenantId: string = this._tenantService.getTenantId();
  isMobile: boolean;

  viewMobileSubscription: Subscription;

  listItemsSidebar: SidebarItem[];

  modalActions: BsModalRef;

  copyright: string;
  version: string;

  hasPermissions: boolean = false;

  private _destroy$: Subject<void> = new Subject<void>();

  private _intervalId: any;
  private _attempts: number = 0;
  private _maxAttempts: number = 5;

  constructor(
    private _meService: MeService,
    private _tenantService: TenantService,
    private _breakpointObserver: BreakpointObserver,
    private _accessUtilsService: AccessUtilsService,
    private _authenticationService: AuthenticationService,
    private _modalService: BsModalService,
  ) {
    this.viewMobileSubscription = this._breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
  }

  ngOnInit(): void {
    this.checkPermision();

    this.startPermissionCheck();

    let currentYear = new Date().getFullYear();
    this.copyright = `© ${currentYear} Vectore Web App`;
    this.version = `${VERSION}`;
  }

  private startPermissionCheck(): void {
    this._intervalId = setInterval(() => {
      this._attempts++;
      this.checkPermision();

      if (this._attempts >= this._maxAttempts) {
        this.clearIntervalId();
      }
    }, 300);
  }

  private clearIntervalId(): void {
    if (this._intervalId) {
      clearInterval(this._intervalId);
      this._intervalId = null;
    }
  }

  checkPermision() {
    this._meService.getMePermissions$(this.tenantId)
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe({
        next: (response) => {
          if (response) {
            this.hasPermissions = response.permissions?.length > 0;
            this.listItemsSidebar = this._accessUtilsService.getAllNavs(response.permissions, this.isMobile);
            if (this._intervalId) {
              clearInterval(this._intervalId);
              this._intervalId = null;
            }
          }
        },
        error: (error) => {
          this.hasPermissions = false;
          if (error.status !== 401) {
            if (this._intervalId) {
              clearInterval(this._intervalId);
              this._intervalId = null;
            }
            Sentry.captureException(error);
          }
        }
      });
  }

  openModalActions() {
    this.modalActions = this._modalService.show(ActionsModalComponent, {
      class: 'modal-md',
      animated: true,
    });
  }

  logout() {
    this._authenticationService.logout();
  }

  ngOnDestroy(): void {
    if (this.viewMobileSubscription) {
      this.viewMobileSubscription.unsubscribe();
    }
  }
}

export interface SidebarItem {
  name?: string;
  label?: string;
  icon?: string;
  color?: string;
  routerLink?: string;
  linkQueryParam?: Params;
  href?: string;
  canExpand?: boolean;
  isExpanded?: boolean;
  children?: SidebarItem[];
  permissions?: string[];
}