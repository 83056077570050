import { Component } from '@angular/core';
import { Breadcrumb, BreadcrumbService } from './breadcrumb.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
    selector: 'breadcrumb',
    template: `
    <ul class="breadcrumb" [ngClass]="{'m-0': isMobile}">
      <li>
        <a class="d-flex gap-1 active" [routerLink]="['/']">
          <i class="bi bi-house color-primary"></i>
          <div>Home</div>
        </a>
      </li>

      <li *ngFor="let breadcrumb of (breadcrumbs$ | async) as breadcrumbs">
        <span class="px-2">/</span>
        <a class="active" [routerLink]="breadcrumb.url">{{ breadcrumb.label | translate }}</a>
      </li>
    </ul>
  `,
    styleUrls: ['./breadcrumb.component.scss'],
    standalone: false
})
export class BreadcrumbComponent {
  breadcrumbs$: Observable<Breadcrumb[]>;
  isMobile = false;
  destroy$: Subject<void> = new Subject<void>();

  constructor(
    private breadcrumbService: BreadcrumbService,
    private _breakpointObserver: BreakpointObserver,
  ) { 
    this._breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
  }

  ngOnInit() {
    this.breadcrumbs$ = this.breadcrumbService.getBreadcrumbs();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
