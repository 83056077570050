import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TaskClient } from 'src/app/shared/models/task/task';
import { DateUtilsService } from 'src/app/shared/utilities/date-utils.service';
import { TaskUtilsService } from 'src/app/shared/utilities/task-utils.service';

@Component({
    selector: 'progressive-bar',
    imports: [CommonModule],
    template: `
    <div class="progress" *ngIf="showProgressBar">
        <div [class]="'progress-bar ' + task.statusClient.cssClass" 
              role="progressbar" 
              [style.width]="remainingTime + '%'" 
              [attr.aria-valuenow]="remainingTime" 
              aria-valuemin="0" 
              aria-valuemax="100">
        </div>
    </div>
  `,
})
export class ProgressiveBarComponent {
  showProgressBar: boolean = false;

  @Input() task: TaskClient;
  @Input() taskEditForm: FormGroup;

  remainingTime: number;
  remaingDays: string;

  private expirationMileageSubscription: Subscription;
  private expirationDateSubscription: Subscription;

  constructor(
    private _taskUtilsService: TaskUtilsService,
    private _dateUtilsService: DateUtilsService,
    private _translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.showProgressBar = this._taskUtilsService.setShowProgressBarTask(this.task);
  }

  setProgressBar() {
    const now = new Date();
    const expirationDate = new Date(this.task.expirationDate);

    if (expirationDate > now) {
      const diffTime = Math.abs(expirationDate.getTime() - now.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      this.remainingTime = 100 - (diffDays * 100) / 30;
      return this.remainingTime;
    } else {
      return this.remainingTime = 100;
    }
  }

  setRemainingDays() {
    const now = new Date();
    const expirationDate = new Date(this.task.expirationDate);

    if (expirationDate > now) {
      const diffTime = Math.abs(expirationDate.getTime() - now.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      this.remaingDays = diffDays + ' ' + this._translateService.instant('TASKS.MODAL_EDIT_TASK.DAYS_LEFT');
    } else {
      this.remaingDays = '';
    }
  }

  updateTaskStatusColor() {
    this.expirationDateSubscription = this.taskEditForm.get('expirationDate').valueChanges.subscribe((value) => {
      if (this._dateUtilsService.isDateValid(value)) {
        this.task.expirationDate = value;

        this.task = this._taskUtilsService.setStatusBadgeTask(this.task);
        this.showProgressBar = this._taskUtilsService.setShowProgressBarTask(this.task);
      }
      else {
        this.task.expirationDate = null;
        this.task = this._taskUtilsService.setStatusBadgeTask(this.task);
        this.showProgressBar = this._taskUtilsService.setShowProgressBarTask(this.task);
      }
    });

    this.expirationMileageSubscription = this.taskEditForm.get('expirationMileage').valueChanges.subscribe((value) => {
      if (value) {
        this.task.expirationMileage = value;
        this.task = this._taskUtilsService.setStatusBadgeTask(this.task);
        this.showProgressBar = this._taskUtilsService.setShowProgressBarTask(this.task);
      }
      else {
        this.task.expirationMileage = null;
        this.task = this._taskUtilsService.setStatusBadgeTask(this.task);
        this.showProgressBar = this._taskUtilsService.setShowProgressBarTask(this.task);
      }
    });
  }

  ngOnDestroy() {
    if (this.expirationDateSubscription) {
      this.expirationDateSubscription.unsubscribe();
    }

    if (this.expirationMileageSubscription) {
      this.expirationMileageSubscription.unsubscribe();
    }
  }
}
