<div class="card-notification">
  <div class="card-notification-header">

    <div class="d-flex gap-2">
      <i class="bg-icon-azure-light bi-bell default-icon p-1"></i>
      <div class="title">{{ "NOTIFICATION.TITLE" | translate }}</div>
    </div>

    @if (notificationsViewModel?.length > 0) {
      <i class="bi bi-envelope-open cursor-pointer"
        (click)="markAllNotificationsAsRead()"
        [pTooltip]="'NOTIFICATION.SIGN_READ_ALL' | translate"
        placement="left">
      </i>
    }

  </div>

  @if (notificationsViewModel && notificationsViewModel?.length > 0) {
    <div class="card-notification-body"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [alwaysCallback]="true"
      [scrollWindow]="false"
      (scrolled)="onScrollDown()">

      <div class="container-item" *ngFor="let notification of notificationsViewModel" >
        <div class="item" [routerLink]="notification?.link" [queryParams]="notification?.queryParams">
          <div [class]="'avatar-placeholder-sm ' + notification?.avatar"></div>

          <div class="item-information">

            <div class="d-flex justify-content-between">

              <div class="item-title">{{ notification?.title | translate }}</div>
              <div class="trash" (click)="markNotificationAsRead(notification.id)">
                <i class="bi bi-trash"></i>
              </div>

            </div>

            <div class="d-flex justify-content-between">

              <div class="item-description d-flex gap-2">
                @if (notification?.description) {
                  <div>{{ notification?.description | translate }}</div>
                }

                @if (notification?.badge) {
                  <div [class]="'custom-badge-bold ' + notification?.badgeClass">
                    {{ notification?.badge | translate }}
                  </div>
                }

              </div>

              @if (notification?.time) {
                <span class="item-time">{{ notification?.time | date : "HH:mm dd/MM" }}</span>
              }

            </div>

            @if (notification?.footerDescription) {
              <span class="item-footer">
                {{ notification?.footerDescription | translate }}
              </span>
            }

          </div>
        </div>
      </div>
    </div>
  }

  @else {
    <div class="card-notification-body py-4 my-4">
      <no-items [message]="'NOTIFICATION.NO_ITEMS'"></no-items>
    </div>
  }
</div>
