import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

@Component({
    selector: 'input-date-formly',
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule,
        TypeaheadModule,
    ],
    template: `
    <div class="form-group">
      <label class="form-label" *ngIf="to.label">{{ to.label }} <span *ngIf="to.required">*</span></label>
      <div class="input-gs-group d-flex align-items-center justify-content-between border-gs rounded mb-3" 
        [ngClass]="{'is-invalid': formControl.invalid && formControl.touched}">
                    
        <input type="text" class="form-control-gs" inputmode='none'
            bsDatepicker 
            #dp="bsDatepicker" 
            [bsConfig]="{ 
              withTimepicker: true,
              keepDatepickerOpened: true,
              dateInputFormat: 'DD-MM-YYYY - h:mm a', 
              isAnimated: true, 
              adaptivePosition: true 
            }" 
            placement="top" 
            [formControl]="formControl">
        
        <i class="icon bi bi-calendar-check text-black-50" (click)="dp.toggle()" [attr.aria-expanded]="dp.isOpen" type="button"></i>
        
      </div>
    </div>
  `,
    styleUrls: ['./input-date-formly.component.scss']
})
export class InputDateFormlyComponent extends FieldType<FieldTypeConfig> {}