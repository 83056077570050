import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskEditModalComponent } from './task-edit-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TaskDescriptionEditComponent } from './task-description-edit/task-description-edit.component';
import { TaskInformationEditComponent } from './task-information-edit/task-information-edit.component';
import { ButtonLoveComponent } from './button-love/button-love.component';
import { AvatarGroupComponent } from './avatar-group/avatar-group.component';

import { TaskSectionFilesComponent } from './task-section-files/task-section-files.component';
import { TaskSectionCommentsComponent } from './task-section-comments/task-section-comments.component';
import { DropdownStatusTaskComponent } from './dropdown-status-task/dropdown-status-task.component';
import { ProgressiveBarComponent } from './progressive-bar/progressive-bar.component';
import { DropdownActionsDotsComponent } from './dropdown-actions-dots/dropdown-actions-dots.component';
import { TaskDuplicateComponent } from './task-duplicate/task-duplicate.component';
import { TaskLinkInvoicesComponent } from './task-link-invoices/task-link-invoices.component';
import { TaskEndMileageComponent } from './task-end-mileage/task-end-mileage.component';
import { DropdownModule } from '../../../dropdown/dropdown.module';
import { InputUploadFilesComponent } from 'src/app/shared/components/file-manage/input-upload-files/input-upload-files.component';
import { OutputFilesComponent } from 'src/app/shared/components/file-manage/output-files/output-files.component';
import { ButtonAddDateComponent } from '../../../buttons/button-add-date/button-add-date.component';
import { ButtonAddAssetComponent } from '../../../buttons/button-add-asset/button-add-asset.component';
import { ButtonAddVehicleComponent } from '../../../buttons/button-add-vehicle/button-add-vehicle.component';
import { ButtonAddUserSecondaryComponent } from '../../../buttons/button-add-user-secondary/button-add-user-secondary.component';
import { ButtonAddUserPrimaryComponent } from '../../../buttons/button-add-user-primary/button-add-user-primary.component';
import { CardAddCostsComponent } from '../../../cards/card-add/card-add-costs/card-add-costs.component';
import { CardTaskUsageDateComponent } from '../../../cards/card-task-usage-date/card-task-usage-date.component';
import { PipeModule } from 'src/app/shared/pipes/pipe.module';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    TaskEditModalComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    DropdownModule,

    InputUploadFilesComponent,
    OutputFilesComponent,

    ButtonAddDateComponent,
    ButtonAddUserPrimaryComponent,
    ButtonAddUserSecondaryComponent,
    ButtonAddVehicleComponent,
    ButtonAddAssetComponent,

    CardTaskUsageDateComponent,
    CardAddCostsComponent,

    TooltipModule,
    PipeModule,

    TaskEndMileageComponent,
    TaskDescriptionEditComponent,
    TaskInformationEditComponent,
    TaskDuplicateComponent,
    TaskLinkInvoicesComponent,

    ButtonLoveComponent,
    AvatarGroupComponent,

    TaskSectionFilesComponent,
    TaskSectionCommentsComponent,

    DropdownStatusTaskComponent,
    DropdownActionsDotsComponent,
    ProgressiveBarComponent,    
  ],
  exports: [ 
    TaskEditModalComponent,
  ]
})
export class TaskModalEditModule { }
