import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskCreateModalComponent as TaskCreateModalComponent } from './task-create-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { InputDateFormlyComponent } from '../../../inputs/input-date-formly/input-date-formly.component';
import { DropdownModule } from '../../../dropdown/dropdown.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    TaskCreateModalComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TypeaheadModule.forRoot(),
    FormlyModule.forRoot({
      types: [
        {
          name: 'ngx-bootstra-date-picker',
          component: InputDateFormlyComponent,
        }
      ]
    }),
    FormlyBootstrapModule,
    BsDatepickerModule,
    TranslateModule.forChild(),
    DropdownModule
  ],
  exports: [
    TaskCreateModalComponent
  ]
})
export class TaskCreateModalModule { }
