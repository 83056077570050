import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TenantService } from './shared/api-services/tenant.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent {
  tenantId: string;

  isHovering = false;
  isMobile = false;

  constructor(
    public router: Router,
    private _breakpointObserver: BreakpointObserver,
    private _tenantService: TenantService,
  ) {
    this.tenantId = this._tenantService.getTenantId();

    this._breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateHoveringStateBasedOnUrl(event.url);
      }
    });
  }

  updateHoveringStateBasedOnUrl(url: string) {
    if (url === '/login' || url === '/registration') {
      this.isHovering = false;
    }
  }
}
