
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject, delay, filter, of, retry, takeUntil } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import * as RoutesUrl from 'src/app/shared/constant/app-routes';
import { BsModalRef } from "ngx-bootstrap/modal";
import { CommunicationService } from "src/app/shared/utilities/comunication.service";
import { AccessUtilsService } from "src/app/shared/utilities/access-utils.servic";
import { ModalUtilsService } from "src/app/shared/utilities/modal-utils.service";
import { MeService } from "src/app/shared/api-services/me.service";
import { Me } from 'src/app/shared/models/me/me';
import * as Permissions from 'src/app/shared/constant/permissions';
import * as Sentry from "@sentry/angular";

@Component({
    selector: 'navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    standalone: false
})
export class NavbarComponent implements OnInit, OnDestroy {
  tenantId: string = this._tenantService.getTenantId();
  namePage: string;

  me: Me;

  srcImage: Observable<SafeUrl>;
  displayName: string;

  isMobile: boolean = false;

  listItemsSidebar: NavbarMobile[];

  modalPerson: BsModalRef;

  hasNews: boolean;
  showIconImports: boolean = false;

  private _destroy$: Subject<void> = new Subject<void>();

  private _intervalId: any;
  private _attempts: number = 0;
  private _maxAttempts: number = 3;

  constructor(
    private _tenantService: TenantService,
    private _meService: MeService,
    private _communicationService: CommunicationService,
    private _router: Router,
    private _breakpointObserver: BreakpointObserver,
    private _authenticationService: AuthenticationService,
    private _accessUtilsService: AccessUtilsService,
    private _modalUtilsService: ModalUtilsService
  ) {
    this._breakpointObserver
      .observe([Breakpoints.Handset])
      .pipe(takeUntil(this._destroy$))
      .subscribe(result => {
        this.isMobile = result.matches;
      });
  }

  ngOnInit(): void {
    this.getUrl();
    this.startPermissionCheck();

    this._communicationService.getEventToUpdateNavbar$()
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.getData();
      });
  }

  private startPermissionCheck(): void {
    this._intervalId = setInterval(() => {
      this._attempts++;
      this.getData();

      if (this._attempts >= this._maxAttempts) {
        this.clearIntervalId();
      }
    }, 300);
  }

  private clearIntervalId(): void {
    if (this._intervalId) {
      clearInterval(this._intervalId);
      this._intervalId = null;
    }
  }

  private getUrl() {
    this.setPageName(this._router.url);
    this._router.events.pipe(
      takeUntil(this._destroy$),
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      const navigationEnd = event as NavigationEnd;
      this.setPageName(navigationEnd.urlAfterRedirects);
    });
  }

  private getData() {
    this._meService.me$
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe({
        next: (response: Me) => {
          if (response) {
            this.me = response;
            this.srcImage = this._meService.getMeImage$(this.tenantId, 32, 32, true);
            this.displayName = response?.displayName;
          }
        }
      });

    this._meService.getMePermissions$(this.tenantId)
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe({
        next: (response) => {
          this.listItemsSidebar = this._accessUtilsService.getAllNavs(response.permissions, this.isMobile);
          this.showIconImports = response.permissions.includes(Permissions.CAN_MANAGE_IMPORTS);

          if (this._intervalId) {
            clearInterval(this._intervalId);
            this._intervalId = null;
          }
        },
        error: (error) => {
          if (error.status !== 401) {
            if (this._intervalId) {
              clearInterval(this._intervalId);
              this._intervalId = null;
            }
            Sentry.captureException(error);
          }
        }
      });
  }

  toggleChevron(item: NavbarMobile): void {
    if (item.canExpand) {
      item.isExpanded = !item.isExpanded;
    }
  }

  logout() {
    this._authenticationService.logout();
  }

  editProfileModal() {
    this._modalUtilsService.openPersonModal({ me: this.me }).subscribe();
  }

  setPageName(url: string): void {
    switch (true) {
      case url.includes(RoutesUrl.HOME):
        this.namePage = 'NAVBAR.HOMEPAGE';
        break;
      case url.includes(RoutesUrl.DASHBOARD):
        this.namePage = 'NAVBAR.DASHBOARD';
        break;
      case url.includes(RoutesUrl.VEHICLES):
        this.namePage = 'VEHICLESS';
        break;
      case url.includes(RoutesUrl.ASSETS):
        this.namePage = 'ASSET';
        break;
      case url.includes(RoutesUrl.PERSONS):
        this.namePage = 'PEOPLE_ORGANIZATION';
        break;
      case url.includes(RoutesUrl.COSTS):
        this.namePage = 'COSTS.NAME';
        break;
      case url.includes(RoutesUrl.TASKS):
        this.namePage = 'TASK';
        break;
      case url.includes(RoutesUrl.FLEETMANAGER_BOOKING):
        this.namePage = 'FLEET_MANAGEMENT_BOOKING';
        break;
      case url.includes(RoutesUrl.MYBOOKINGS):
        this.namePage = 'MY_BOOKINGS';
        break;
      case url.includes(RoutesUrl.IMPORTS):
        this.namePage = 'IMPORTS';
        break;
      case url.includes(RoutesUrl.REPORTS):
        this.namePage = 'REPORT';
        break;
      case url.includes(RoutesUrl.FLEETLOCATION):
        this.namePage = 'FLEET_LOCATION';
        break;
      case url.includes(RoutesUrl.SETTINGS):
        this.namePage = 'NAVBAR.SETTINGS';
        break;
      default:
        this.namePage = 'NAVBAR.HOMEPAGE';
        break;
    }
  }

  navigateToImports() {
    this._router.navigate([RoutesUrl.IMPORTS]);
  }

  collapseNavbar(): void {
    const navbar = document.getElementById('navbarScroll');
    if (navbar?.classList.contains('show')) {
      navbar.classList.remove('show');
    }
  }

  markButtonNews(news: boolean) {
    this.hasNews = news;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}

export interface NavbarMobile {
  label?: string;
  icon?: string;
  routerLink?: string;
  canExpand?: boolean;
  isExpanded?: boolean;
  children?: NavbarMobile[];
}