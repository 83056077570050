import { Component, Input } from '@angular/core';
import { BookingModalService } from '../booking-modal.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

export interface BookingModalFooter {
  id: number;
  primaryButton?: string;
  secondaryButton?: string;
  primaryAction?: () => void;
  secondaryAction?: () => void;
}

@Component({
    selector: 'booking-modal-footer',
    template: `
    <div class="card-create-booking-footer mt-3 py-3">
      @for (item of bookingModalFooter; track item.id) {
        @if (currentStep === item.id) {
          <div class="d-flex gap-3 align-items-center justify-content-center">
            @if (item.primaryButton) {
              <button type="button" class="btn-gs-secondary" (click)="item.primaryAction()">{{item.primaryButton | translate}}</button>
            }
            @if (item.secondaryButton) {
              <button type="button" class="btn-gs-primary" (click)="item.secondaryAction()">{{item.secondaryButton | translate}}</button>
            }
          </div>
        }
      }
    </div>
  `,
    standalone: false
})
export class BookingModalFooterComponent {

  @Input() bsModalRef!: BsModalRef;

  get currentStep() {
    return this._bookingModalNewService.currentStep;
  }

  get bookingModalFooter() {
    return this._bookingModalNewService.bookingModalFooter;
  }

  constructor(
    private _bookingModalNewService: BookingModalService
  ) { }  

}